<template>
  <div
    v-if="course"
    class="row"
  >
    <b-card class="col px-0 text-center">
      <h3 class="font-weight-bold mt-2 w-75 mx-auto">
        {{ course.title }}
      </h3>
      <!-- eslint-disable-next-line -->
      <div class="col-12 col-md-10 mx-auto px-0 mt-1 video-embed" v-html="course.embedded"></div>
      <img
        v-if="course.owner.image"
        class="mt-1 course-workplace-logo"
        :src="course.owner.image"
        alt=""
      >
      <div class="mt-2">
        <h4 class="font-weight-bolder">
          {{
            {
              date: course.start_date,
              locale: $i18n.locale,
            } | transformDate
          }}
        </h4>
        <h6>
          {{ course.start_time }}
          -
          {{
            {
              startTime: course.start_time,
              duration: course.estimated_duration,
            } | endTime
          }}
        </h6>
      </div>
      <div
        v-if="!status.isLive && !status.isFinished && countdown"
        class="row w-75 mt-1 mx-auto justify-content-center"
      >
        <div class="col-3 col-md-1 bg-secondary time-col">
          <h3
            :class="[
              'font-weight-bold',
              countdown.days() === 0 ? 'text-muted' : '',
            ]"
          >
            {{ countdown.days() }}
          </h3>
          <h6 :class="countdown.days() === 0 ? 'text-muted' : ''">
            {{ $t("Days") }}
          </h6>
        </div>
        <div class="col-3 col-md-1 bg-secondary time-col">
          <h3
            :class="[
              'font-weight-bold',
              countdown.hours() === 0 ? 'text-muted' : '',
            ]"
          >
            {{ countdown.hours() }}
          </h3>
          <h6 :class="countdown.hours() === 0 ? 'text-muted' : ''">
            {{ countdown.hours() === 1? $t("Hour") : $t("Hours") }}
          </h6>
        </div>
        <div class="col-3 col-md-1 bg-secondary time-col">
          <h3
            :class="[
              'font-weight-bold',
              countdown.minutes() === 0 ? 'text-muted' : '',
            ]"
          >
            {{ countdown.minutes() }}
          </h3>
          <h6 :class="countdown.minutes() === 0 ? 'text-muted' : ''">
            {{ $t("Min") }}
          </h6>
        </div>
        <div class="col-3 col-md-1 bg-secondary time-col">
          <h3
            :class="[
              'font-weight-bold',
              countdown.seconds() === 0 ? 'text-muted' : '',
            ]"
          >
            {{ countdown.seconds() }}
          </h3>
          <h6 :class="countdown.seconds() === 0 ? 'text-muted' : ''">
            {{ $t("Sec") }}
          </h6>
        </div>
      </div>
      <h4
        v-if="status.isLive"
        class="font-weight-bolder"
      >
        {{ $t("Now the webinar is live") }}
      </h4>
      <h4
        v-if="status.isFinished"
        class="font-weight-bolder"
      >
        {{ $t("Now the webinar is finished") }}
      </h4>
      <b-button
        v-if="course.is_subscribed && !status.isFinished"
        class="mt-1"
        variant="primary"
        @click="attendWebinar()"
      >
        {{ $t("Leave webinar") }}
      </b-button>
      <b-button
        v-else-if="!status.isFinished"
        class="mt-1"
        variant="primary"
        @click="attendWebinar()"
      >
        {{ $t("Attend webinar") }}
      </b-button>
      <div class="justify-content-center row mx-0 mt-2 mb-1 p-0">
        <h4
          v-for="(speciality, index) in translate(course.specialities, $i18n.locale)"
          :key="index"
          class="mr-1"
        >
          <b-badge
            class="text-dark font-weight-normal"
            variant="secondary"
          >
            {{ speciality.name }}
          </b-badge>
        </h4>
      </div>
      <!-- eslint-disable-next-line -->
      <h5 class="w-75 mx-auto" v-html="course.description" />
    </b-card>
  </div>
</template>
<script>
import moment from 'moment'
import { BButton, BBadge, BCard } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BBadge,
    BCard,
  },
  filters: {
    transformDate(obj) {
      moment.locale(obj.locale)
      if (obj.locale === 'en') {
        return moment(obj.date).format('MMMM Do YYYY')
      }
      moment.locale('nb')
      return moment(obj.date).format('DD. MMMM, YYYY')
    },
    endTime(time) {
      return moment(time.startTime, 'H:mm')
        .add(+time.duration, 'minutes')
        .format('H:mm')
    },
  },
  data() {
    return {
      duration: null,
    }
  },
  computed: {
    course() {
      return this.$store.getters[`${this.$route.params.type === 'private' ? 'myCourse' : 'course'}/selectedCourse`]
    },
    countdown() {
      return this.duration
    },
    status() {
      const startDateTime = moment(
        `${this.course.start_date} ${this.course.start_time}`,
      ).valueOf()
      const currentDateTime = moment(Date.now()).valueOf()
      const endTime = moment(this.course.start_time, 'H:mm')
        .add(+this.course.estimated_duration, 'minutes')
        .format('H:mm')
      const endDateTime = moment(
        `${this.course.start_date} ${endTime}`,
      ).valueOf()
      return {
        isLive:
          startDateTime < currentDateTime && endDateTime > currentDateTime,
        isFinished: endDateTime < currentDateTime,
      }
    },
  },
  async mounted() {
    await this.$store.dispatch(`${this.$route.params.type === 'private' ? 'myCourse' : 'course'}/fetchCourseById`, this.$route.params.id)
    this.duration = moment.duration(
      moment(`${this.course.start_date} ${this.course.start_time}`).diff(
        moment(),
      ),
    )

    await this.$store.dispatch('course/userEnteredOnCourse', this.$route.params.id)

    window.addEventListener('beforeunload', this.handleUserLeftWatchingCourse, false)
    window.addEventListener('unload', this.handleUserLeftWatchingCourse, false)

    setInterval(() => {
      this.duration.subtract(1, 'seconds')
    }, 1000)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleUserLeftWatchingCourse)
    window.removeEventListener('unload', this.handleUserLeftWatchingCourse)
  },
  destroyed() {
    this.handleUserLeftWatchingCourse()
  },
  methods: {
    attendWebinar() {
      this.$store
        .dispatch(
          this.course.is_subscribed
            ? 'course/leaveCourse'
            : 'course/subscribeCourse',
          this.$route.params.id,
        )
        .then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(
                  this.course.is_subscribed
                    ? 'You have successfully leaved the course'
                    : 'You have successfully subscribed for the course',
                ),
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            this.$store.dispatch(
              'course/fetchCourseById',
              this.$route.params.id,
            )
          }
        })
    },
    handleUserLeftWatchingCourse() {
      this.$store.dispatch('course/userLeftWatchingCourse')
    },
  },
}
</script>
