<template>
  <div>
    <div v-if="are_details_open">
      <course-details />
    </div>
    <div
      v-else
      class="d-flex flex-md-row mt-2 flex-column-reverse"
    >
      <div class="col-md-9 nopadding">
        <b-button-group class="w-100 mb-2">
          <b-button
            :class="{ active_tab: activeTab === 0 }"
            variant="outline-primary"
            @click="changeTab(0)"
          >
            {{ $t("Webinars") }}
          </b-button>
          <b-button
            :class="{ active_tab: activeTab === 1 }"
            variant="outline-primary"
            @click="changeTab(1)"
          >
            {{ $t("My courses") }}
          </b-button>
        </b-button-group>
        <router-view />
      </div>
      <div class="col-md-3 pl-md-1 pr-md-0 p-0">
        <b-input-group class="input-group-merge mb-1">
          <b-input-group-prepend
            class="no-border"
            is-text
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>

          <b-form-input
            v-model="search"
            type="search"
            class="no-border"
            :placeholder="$t('Search...')"
          />
        </b-input-group>
        <b-card body-class="p-1">
          <div>
            <h4 class="font-weight-bolder">
              {{ $t("Old webinars and courses") }}
            </h4>
            <b-form-checkbox
              v-model="showOldCourse"
              class="custom-control-primary mt-1"
            >
              <h5>{{ $t('Hide archived') }}</h5>
            </b-form-checkbox>
          </div>
        </b-card>
        <filter-card page="course" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import { debounce } from 'vue-debounce'
import FilterCard from '@/components/course/FilterCard.vue'
import CourseDetails from '@/views/course/CourseDetails.vue'

export default {
  components: {
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    FilterCard,
    CourseDetails,
    BCard,
    BFormCheckbox,
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    showOldCourse: {
      get() {
        return this.$store.getters['course/showOldCourse']
      },
      set(value) {
        this.$store.commit('course/setShowOldCourse', value)
      },
    },
    are_details_open() {
      return this.$route.params.id
    },
    search: {
      get() {
        return this.$store.getters['course/search']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('course/setSearch', search)
      }, 1000),
    },
    activeTab: {
      get() {
        return this.$store.getters['course/activeTab']
      },
      set(tab) {
        this.$store.commit('course/setActiveTab', tab)
      },
    },
    query() {
      return this.$store.getters['course/query']
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompanyAdmin() {
      return 'id' in this.user.active_business_profile && this.user.active_business_profile.role === 'admin'
    },
    showNoAccessInfoCard() {
      if (!this.isAuthenticated) return false
      if (this.representACompanyAdmin) return false
      if (!this.checkPermission('COURSE_READ', this.userPermissions)) return true
      return false
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.query.filter': {
      handler() {
        if (!this.showNoAccessInfoCard) {
          if (this.activeTab) {
            this.$store.dispatch('course/fetchMyCourses')
          } else this.$store.dispatch('course/fetchCourses')
        }
      },
      deep: true,
    },
    query: {
      handler(query) {
        this.updateQuery(query)
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.filter) {
      this.$store.commit(
        'course/setQuery',
        JSON.parse(this.$route.query.filter),
      )
    }
    if (!this.showNoAccessInfoCard) {
      this.$store.dispatch('course/fetchCourses')
    }
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('filterData/fetchCounties')
  },
  beforeDestroy() {
    this.$store.commit('course/clearFilters')
  },
  methods: {
    updateQuery(query) {
      this.$router.push({
        path: `/course/${this.activeTab ? 'my_courses' : 'webinares'}`,
        query: { filter: JSON.stringify(query) },
      }).catch(() => {})
    },
    changeTab(tab) {
      this.activeTab = tab
      this.$store.commit('course/setPageNumber', 1)
      if (tab) {
        this.$router.push('/course/my_courses').catch(() => {})
      } else {
        this.$router.push('/course/webinares').catch(() => {})
      }
    },
  },
}
</script>
