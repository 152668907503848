var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.course)?_c('div',{staticClass:"row"},[_c('b-card',{staticClass:"col px-0 text-center"},[_c('h3',{staticClass:"font-weight-bold mt-2 w-75 mx-auto"},[_vm._v(" "+_vm._s(_vm.course.title)+" ")]),_c('div',{staticClass:"col-12 col-md-10 mx-auto px-0 mt-1 video-embed",domProps:{"innerHTML":_vm._s(_vm.course.embedded)}}),(_vm.course.owner.image)?_c('img',{staticClass:"mt-1 course-workplace-logo",attrs:{"src":_vm.course.owner.image,"alt":""}}):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('h4',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm._f("transformDate")({ date: _vm.course.start_date, locale: _vm.$i18n.locale, }))+" ")]),_c('h6',[_vm._v(" "+_vm._s(_vm.course.start_time)+" - "+_vm._s(_vm._f("endTime")({ startTime: _vm.course.start_time, duration: _vm.course.estimated_duration, }))+" ")])]),(!_vm.status.isLive && !_vm.status.isFinished && _vm.countdown)?_c('div',{staticClass:"row w-75 mt-1 mx-auto justify-content-center"},[_c('div',{staticClass:"col-3 col-md-1 bg-secondary time-col"},[_c('h3',{class:[
            'font-weight-bold',
            _vm.countdown.days() === 0 ? 'text-muted' : '' ]},[_vm._v(" "+_vm._s(_vm.countdown.days())+" ")]),_c('h6',{class:_vm.countdown.days() === 0 ? 'text-muted' : ''},[_vm._v(" "+_vm._s(_vm.$t("Days"))+" ")])]),_c('div',{staticClass:"col-3 col-md-1 bg-secondary time-col"},[_c('h3',{class:[
            'font-weight-bold',
            _vm.countdown.hours() === 0 ? 'text-muted' : '' ]},[_vm._v(" "+_vm._s(_vm.countdown.hours())+" ")]),_c('h6',{class:_vm.countdown.hours() === 0 ? 'text-muted' : ''},[_vm._v(" "+_vm._s(_vm.countdown.hours() === 1? _vm.$t("Hour") : _vm.$t("Hours"))+" ")])]),_c('div',{staticClass:"col-3 col-md-1 bg-secondary time-col"},[_c('h3',{class:[
            'font-weight-bold',
            _vm.countdown.minutes() === 0 ? 'text-muted' : '' ]},[_vm._v(" "+_vm._s(_vm.countdown.minutes())+" ")]),_c('h6',{class:_vm.countdown.minutes() === 0 ? 'text-muted' : ''},[_vm._v(" "+_vm._s(_vm.$t("Min"))+" ")])]),_c('div',{staticClass:"col-3 col-md-1 bg-secondary time-col"},[_c('h3',{class:[
            'font-weight-bold',
            _vm.countdown.seconds() === 0 ? 'text-muted' : '' ]},[_vm._v(" "+_vm._s(_vm.countdown.seconds())+" ")]),_c('h6',{class:_vm.countdown.seconds() === 0 ? 'text-muted' : ''},[_vm._v(" "+_vm._s(_vm.$t("Sec"))+" ")])])]):_vm._e(),(_vm.status.isLive)?_c('h4',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Now the webinar is live"))+" ")]):_vm._e(),(_vm.status.isFinished)?_c('h4',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Now the webinar is finished"))+" ")]):_vm._e(),(_vm.course.is_subscribed && !_vm.status.isFinished)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.attendWebinar()}}},[_vm._v(" "+_vm._s(_vm.$t("Leave webinar"))+" ")]):(!_vm.status.isFinished)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.attendWebinar()}}},[_vm._v(" "+_vm._s(_vm.$t("Attend webinar"))+" ")]):_vm._e(),_c('div',{staticClass:"justify-content-center row mx-0 mt-2 mb-1 p-0"},_vm._l((_vm.translate(_vm.course.specialities, _vm.$i18n.locale)),function(speciality,index){return _c('h4',{key:index,staticClass:"mr-1"},[_c('b-badge',{staticClass:"text-dark font-weight-normal",attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(speciality.name)+" ")])],1)}),0),_c('h5',{staticClass:"w-75 mx-auto",domProps:{"innerHTML":_vm._s(_vm.course.description)}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }